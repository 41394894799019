import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  //{ path: 'IsAdmin', loadChildren: () => import('./is-admin/is-admin.module').then(m => m.IsAdminModule) },
  //{ path: 'UserRegistration', loadChildren: () => import('./user-registration/user-registration.module').then(m => m.UserRegistrationModule) },
  //{ path: 'ShareRegistratonLink', loadChildren: () => import('./share-registraton-link/share-registraton-link.module').then(m => m.ShareRegistratonLinkModule) },
  //{ path: 'ShareAdminLink', loadChildren: () => import('./share-admin-link/share-admin-link.module').then(m => m.ShareAdminLinkModule) },
  { path: 'AdminMain/:token', loadComponent: () => import('./admin-main/admin-main.component').then(mod => mod.AdminMainComponent), canActivate: [AuthGuard] },
  { path: 'UserMain/:token', loadComponent: () => import('./user-main/user-main.component').then(mod => mod.UserMainComponent), canActivate: [AuthGuard]},
  { path: 'ErroPage/:type', loadComponent: () => import('./erro-page/erro-page.component').then(mod => mod.ErroPageComponent) },
  { path: '', redirectTo: 'ErroPage/notfound', pathMatch: 'full' },
  { path: 'UserRegistrationReport', loadChildren: () => import('./user-registration-report/user-registration-report.module').then(m => m.UserRegistrationReportModule), canActivate: [AuthGuard] },
  //{ path: '**', redirectTo: 'ErroPage/notfound' }, // Wildcard route for undefined paths
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
